import { createContext, useContext } from 'react'
import { Member } from '../../Types/members'

interface MemberContextProps {
  members: Member[]
  selectedMemberId: string | null
  fetchMembers: () => void
  addMember: (member: Member) => void
  removeMember: (id: string) => void
  setSelectedMemberId: (id: string) => void
}

const MembersContext = createContext<MemberContextProps>({
  members: [],
  selectedMemberId: null,
  fetchMembers: () => {},
  addMember: () => {},
  removeMember: () => {},
  setSelectedMemberId: () => {}
})

// const MembersContext = createContext<MemberContextProps | undefined>(undefined)

export const useMemberContext = (): MemberContextProps => {
  const context = useContext(MembersContext)
  if (!context) {
    throw new Error('useMemberContext must be used within a MembersProvider')
  }
  return context
}

export default MembersContext
