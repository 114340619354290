import { useContext, useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datePickerStyles.css'
import { ptBR } from 'date-fns/locale'
import * as S from './styles'
import { BoxType, ScheduleContainer } from 'src/styles'
import { useDate } from '../../contexts/Date/DateContext'
import SessionContext from 'src/contexts/Sessions/SessionContext'
import SessionClientContext from 'src/contexts/SessionClient/SessionClientContext'
import MembersContext from 'src/contexts/Members/MembersContext'

const Schedule: React.FC = () => {
  const [selectedHour, setSelectedHour] = useState('')
  const { selectedDate, setSelectedDate } = useDate()
  const { sessions, setSessionId } = useContext(SessionContext)
  const { addSessionClient } = useContext(SessionClientContext)
  const { selectedMemberId } = useContext(MembersContext)

  console.log('selectedMemberId:', selectedMemberId)

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const adjustedDate = new Date(date.setHours(0, 0, 0, 0))
      setSelectedDate(adjustedDate)
    } else {
      setSelectedDate(new Date())
    }
  }

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHourValue = event.target.value
    setSelectedHour(selectedHourValue)

    const sessionId = sessions
      .filter((session) => session.slot_start === selectedHourValue)
      .map((session) => session.id)[0]

    setSessionId(sessionId)
  }

  const handleSchedule = async () => {
    if (selectedHour) {
      const sessionId = sessions
        .filter((session) => session.slot_start === selectedHour)
        .map((session) => session.id)[0]

      if (sessionId) {
        if (selectedMemberId) {
          const sessionClient = {
            id: crypto.randomUUID(),
            session_id: sessionId,
            client_id: selectedMemberId
          }

          try {
            await addSessionClient(sessionClient)
            alert('Sessão agendada com sucesso!')
            window.location.reload()
          } catch (error) {
            alert('Erro ao agendar a sessão. Tente novamente.')
          }
        } else {
          alert('Por favor, selecione um membro válido.')
        }
      } else {
        alert('Sessão não encontrada.')
      }
    } else {
      alert('Por favor, selecione um horário e um membro.')
    }
  }

  const selectedDateString = selectedDate.toISOString().split('T')[0]

  const availableHours = sessions
    .filter((session) => session.date === selectedDateString)
    .map((session) => session.slot_start)
    .sort((a, b) => a.localeCompare(b))

  useEffect(() => {}, [sessions])

  return (
    <>
      <ScheduleContainer>
        <BoxType>
          <S.Label htmlFor="date">Escolha o dia:</S.Label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            id="date"
            dateFormat="dd/MM/yyyy"
            locale={ptBR}
            className="date-picker"
          />
          <S.Hour>
            <S.Label htmlFor="hour" id="hour">
              Escolha o horário disponível:
            </S.Label>
            <select
              name="hour"
              id="hour"
              value={selectedHour}
              onChange={handleHourChange}
            >
              <option value="">Selecione um horário</option>
              {availableHours.map((hour, index) => (
                <option key={index} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </S.Hour>
        </BoxType>
        <S.SubmitButton onClick={handleSchedule}>Agendar</S.SubmitButton>
      </ScheduleContainer>
    </>
  )
}

export default Schedule
