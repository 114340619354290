import * as S from './styles'
import { useMemberContext } from '../../contexts/Members/MembersContext'
import { useEffect, useState } from 'react'
import { Member } from 'src/Types/members'
export const AutoCompleteName: React.FC = () => {
  const { members, setSelectedMemberId } = useMemberContext()

  const [inputValue, setInputValue] = useState('')
  const [filterSearch, setFilterSearch] = useState<Member[]>([])

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)

    const newFilter = members.filter((member) => {
      return member.name.toLowerCase().includes(inputValue.toLowerCase())
    })

    setFilterSearch(newFilter)
  }

  useEffect(() => {
    if (inputValue === '') {
      setFilterSearch([])
    }
  }, [inputValue])

  function handleClickAutoComplete(member: Member) {
    setInputValue(member.name)
    setFilterSearch([])
    setSelectedMemberId(member.id)
  }

  function clearText() {
    setInputValue('')
    setFilterSearch([])
  }

  return (
    <>
      <S.SearchContainer>
        <S.SearchInput>
          <S.InputName
            id="inputName"
            value={inputValue}
            onChange={handleFilter}
            type="text"
            placeholder="Nome"
          />
          {inputValue !== '' ? <S.StyledIcon onClick={clearText} /> : null}
        </S.SearchInput>
        {filterSearch.length !== 0 && (
          <S.DataResult>
            {filterSearch
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((member) => (
                <S.DataItem
                  key={member.id}
                  onClick={() => handleClickAutoComplete(member)}
                >
                  <p>{member.name}</p>
                </S.DataItem>
              ))}
          </S.DataResult>
        )}
      </S.SearchContainer>
    </>
  )
}
